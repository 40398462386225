<template>
  <el-dialog
    v-elDragDialog
    v-loading="loading"
    width="900px"
    :title="currentItem ? $t('edit') : $t('newadd')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="addProgramModal"
  >
    <div class="mainWrap">
      <div v-if="materialList.length" class="leftPart">
        <el-carousel trigger="click" height="245px" :autoplay="false">
          <el-carousel-item v-for="item in materialList" :key="item.id">
            <img :src="item.sourcePath" v-if="item.type === 1" />
            <video :src="item.sourcePath" controls v-else></video>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="rightPart">
        <el-form :model="form" :rules="rules" size="small" ref="form" label-width="120px">
          <template v-if="active === 0">
            <el-form-item :label="$t('marketManagement.name')" prop="name">
              <el-input v-model.trim="form.name" :placeholder="$t('marketManagement.enterName')" />
            </el-form-item>

            <el-form-item :label="$t('operateList.type')" prop="type">
              <el-select v-model="form.type" @change="typeChange">
                <el-option :label="$t('spaceDetails.photo')" :value="1" />
                <el-option :label="$t('spaceDetails.video')" :value="2" />
                <el-option
                  :label="`${$t('spaceDetails.photo')}+${$t('spaceDetails.video')}`"
                  :value="3"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('marketManagement.direction')" prop="direction">
              <el-select v-model="form.direction">
                <el-option :label="$t('marketManagement.horizontalScreen')" value="1" />
                <el-option :label="$t('marketManagement.verticalScreen')" value="2" />
              </el-select>
            </el-form-item>

            <el-form-item
              v-if="+form.type != 2"
              :label="$t('marketManagement.rotationTime')"
              prop="carouseTime"
            >
              <el-input
                @input="
                  e => {
                    form.carouseTime = form.carouseTime.replace(/[^\d]/g, '')
                  }
                "
                v-model.trim="form.carouseTime"
                :placeholder="$t('marketManagement.enterRotationTime')"
              />
            </el-form-item>

            <el-form-item
              :label="$t('marketManagement.material')"
              :prop="isCreated ? 'sourceIds' : ''"
            >
              <div class="select" @click="selectMaterialVisible = true">
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
              <div v-if="materialList.length" class="materialList">
                <p v-for="(item, index) in materialList" :key="item.id">
                  <span> {{ item.name }} </span>
                  <!-- <span> {{ item.appName }} {{ +item.type === 1 ? '.png' : 'mp4' }} </span> -->
                  <i class="el-icon-close" @click="delHandler(index)"></i>
                </p>
              </div>
            </el-form-item>
          </template>

          <template v-if="active === 1">
            <el-form-item :label="$t('marketManagement.releaseTime')">
              <el-select v-model="inputType">
                <el-option :label="$t('loginPage.longTerm')" :value="0" />
                <el-option :label="$t('marketManagement.custom')" :value="1" />
              </el-select>
            </el-form-item>
            <el-form-item label="" v-if="inputType === 1">
              <el-date-picker
                style="width:220px"
                v-model="inputDate"
                type="daterange"
                :range-separator="$t('to')"
                :start-placeholder="$t('startDate')"
                :end-placeholder="$t('endDate')"
              />
            </el-form-item>

            <el-form-item :label="$t('marketManagement.playFrequency')">
              <el-select v-model="repeatTime" multiple>
                <el-option
                  v-for="item in repeatTimeList"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('marketManagement.playTime')">
              <el-select v-model="playType">
                <el-option :label="$t('marketManagement.allDay')" :value="0" />
                <el-option :label="$t('marketManagement.custom')" :value="1" />
              </el-select>
            </el-form-item>
            <el-form-item label="" v-if="playType === 1">
              <el-time-picker
                is-range
                style="width:220px"
                v-model="playTime"
                format="HH:mm"
                :range-separator="$t('to')"
                :start-placeholder="$t('startDate')"
                :end-placeholder="$t('endDate')"
              />
            </el-form-item>
            <el-form-item style="margin-bottom:0" :label="$t('groupManagement.flexibleMode')">
              <el-switch v-model="form.flexibleMode" :active-value="1" :inactive-value="0" />
            </el-form-item>
            <div class="modeWrap" v-if="form.flexibleMode === 1">
              <div>
                {{ $t('groupManagement.flexibleModeStandby') }}
                <el-select
                  size="mini"
                  style="width:90px;margin-right:2px"
                  v-model="form.flexibleTime"
                >
                  <el-option
                    v-for="item in sleepList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>

                <!--                 
                <el-input
                  size="mini"
                  style="width:65px;margin-right:2px"
                  v-model="form.flexibleTime"
                  @blur="
                    () => {
                      !form.flexibleTime ? (form.flexibleTime = 30) : ''
                    }
                  "
                  @input="
                    val => {
                      form.flexibleTime = onlyNum(val)
                    }
                  "
                /> -->
                {{ $t('groupManagement.autoRestore') }}
              </div>
              <!-- <p style="color:#d9001b">{{ $t('groupManagement.autoRestoreTips') }}</p> -->
            </div>
          </template>

          <template v-if="active === 2">
            <el-select
              v-model="form.publishRange"
              :placeholder="$t('groupManagement.selectDeliveryMethod')"
              @change="publishWayChange"
            >
              <el-option
                v-for="item in publishWayList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <div class="group" v-if="form.publishRange">
              <div class="g-header">
                <el-button
                  type="primary"
                  size="mini"
                  v-if="form.publishRange === 1"
                  @click="groupVisible = true"
                  >{{ $t('deviceModal.selectGrouping') }}</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  v-if="form.publishRange === 2"
                  @click="snVisible = true"
                  >{{ $t('publishApp.selectDevice') }}</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  v-if="form.publishRange === 3"
                  @click="deviceTypeVisivle = true"
                  >{{ $t('deviceModal.selectDeviceModel') }}</el-button
                >
                <span class="label" v-if="form.publishRange === 6"
                  >{{ $t('publishApp.selected') }}：{{ $t('groupManagement.fullRelease') }}
                  {{ deviceTotal || 0 }}{{ $t('adminHomePage.tower')
                  }}{{ $t('deviceModal.devices') }}</span
                >

                <div
                  class="r"
                  style="margin:0 4px;"
                  v-if="form.publishRange && form.publishRange !== 6"
                >
                  <div class="info">
                    <span class="label"
                      >{{ $t('publishApp.selected') }}：{{ total || 0
                      }}{{ $t('adminHomePage.tower') }}{{ $t('deviceModal.devices') }}</span
                    >
                  </div>
                  <el-link
                    type="danger"
                    :underline="false"
                    icon="el-icon-delete"
                    @click="selectData = []"
                    >{{ $t('publishApp.clearAll') }}</el-link
                  >
                </div>
              </div>
              <div
                class="content"
                :class="!form.publishRange || form.publishRange === 6 ? 'hide' : ''"
              >
                <template v-if="form.publishRange === 1">
                  <p class="noData" v-if="!selectData.length">
                    {{ $t('publishApp.noGroupSelection') }}
                  </p>
                  <template v-else>
                    <div
                      class="item"
                      v-for="(item, index) in selectData"
                      :key="item.id + item.name"
                    >
                      <div class="item-left">
                        <span class="name">{{ item.name }}</span>
                        <span class="num"
                          >{{ $t('publishApp.common') }} {{ item.deviceTotal || 0 }}
                          {{ $t('adminHomePage.tower') }}{{ $t('deviceModal.devices') }}</span
                        >
                      </div>
                      <div class="item-right" @click="selectData.splice(index, 1)">
                        x
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="form.publishRange === 2">
                  <p class="noData" v-if="!selectData.length">
                    {{ $t('publishApp.noGroupSelection') }}
                  </p>
                  <template v-else>
                    <div class="item sn" v-for="(item, index) in selectData" :key="item.sn">
                      <div class="item-left">
                        {{ item.sn }}
                      </div>
                      <div class="item-right" @click="selectData.splice(index, 1)">
                        x
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="form.publishRange === 3">
                  <p class="noData" v-if="!selectData.length">
                    {{ $t('publishApp.noDeviceType') }}
                  </p>
                  <template v-else>
                    <div
                      class="item device-type"
                      v-for="(item, index) in selectData"
                      :key="item.id + item.name"
                    >
                      <div class="item-left" v-viewer>
                        <img :src="item.imagePath" />
                        <div>
                          <span class="name">{{ item.name }}</span>
                          <span class="num"
                            >{{ $t('publishApp.common') }} {{ item.deviceTotal || 0 }}
                            {{ $t('adminHomePage.tower') }}{{ $t('deviceModal.devices') }}</span
                          >
                        </div>
                      </div>
                      <div class="item-right" @click="selectData.splice(index, 1)">
                        x
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <el-button v-if="active === 0" @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button v-if="active !== 0" type="primary" @click="pre">{{
        $t('publishApp.preStep')
      }}</el-button>
      <el-button
        v-if="active <= 2"
        type="primary"
        :disabled="active === 0 && ((!materialList.length && isCreated) || !form.name)"
        @click="next"
        >{{
          active === 2
            ? isCreated
              ? $t('groupManagement.launchNow')
              : $t('save')
            : $t('publishApp.nextStep')
        }}</el-button
      >
    </div>
    <group-modal v-if="groupVisible" :visible.sync="groupVisible" :select-data.sync="selectData" />
    <sn-modal v-if="snVisible" :visible.sync="snVisible" :select-data.sync="selectData" />
    <device-type
      v-if="deviceTypeVisivle"
      :visible.sync="deviceTypeVisivle"
      :select-data.sync="selectData"
    />

    <select-material-modal
      v-if="selectMaterialVisible"
      :visible.sync="selectMaterialVisible"
      :currentType="form.type"
      :operate="$t('groupManagement.launch')"
      @selectMaterial="renderData"
    />
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import GroupModal from '../../../appManagement/components/GroupModal'
import SnModal from '../../../appManagement/components/SnModal'
import DeviceType from '../../../appManagement/components/DeviceType'
import SelectMaterialModal from './SelectMaterialModal'
export default {
  mixins: [dialogCommonParams],
  components: {
    GroupModal,
    SnModal,
    DeviceType,
    SelectMaterialModal
  },
  data() {
    return {
      loading: false,
      selectMaterialVisible: false,
      active: 0,
      inputType: 0,
      playType: 0,
      inputDate: null,
      playTime: null,
      repeatTime: [0],
      form: {
        name: '',
        isAllDayBroadcast: 0,
        isLongTermDelivery: 0,
        carouseTime: 10,
        publishRange: '',
        flexibleTime: 30,
        flexibleMode: 0,
        sourceIds: [],
        type: 1,
        direction: '1'
      },
      sleepList: [
        {
          label: '15' + this.$t('deviceModal.second'),
          value: 15
        },
        {
          label: '30' + this.$t('deviceModal.second'),
          value: 30
        },
        {
          label: '1' + this.$t('deviceModal.minute'),
          value: 60
        },
        {
          label: '2' + this.$t('deviceModal.minute'),
          value: 120
        },
        {
          label: '5' + this.$t('deviceModal.minute'),
          value: 300
        },
        {
          label: '10' + this.$t('deviceModal.minute'),
          value: 600
        },
        {
          label: '30' + this.$t('deviceModal.minute'),
          value: 1800
        }
      ],
      publishWayList: [
        {
          value: 1,
          label: this.$t('groupManagement.launchByGroup')
        },
        {
          value: 2,
          label: this.$t('groupManagement.launchBySN')
        },
        {
          value: 3,
          label: this.$t('groupManagement.launchByModel')
        },
        {
          value: 6,
          label: this.$t('groupManagement.fullRelease')
        }
      ],
      repeatTimeList: [
        {
          label: this.$t('marketManagement.repeatEveryDay'),
          value: 0
        },
        {
          label: this.$t('marketManagement.Monday'),
          value: 2
        },
        {
          label: this.$t('marketManagement.Tuesday'),
          value: 3
        },
        {
          label: this.$t('marketManagement.Wednesday'),
          value: 4
        },
        {
          label: this.$t('marketManagement.Thursday'),
          value: 5
        },
        {
          label: this.$t('marketManagement.Friday'),
          value: 6
        },
        {
          label: this.$t('marketManagement.Saturday'),
          value: 7
        },
        {
          label: this.$t('marketManagement.Sunday'),
          value: 1
        }
      ],
      materialList: [],
      currentWay: '',
      deviceTotal: 0,
      selectData: [],
      groupVisible: false,
      snVisible: false,
      deviceTypeVisivle: false,

      rules: {
        name: [{ required: true, message: this.$t('marketManagement.enterName') }],
        repeatTime: [{ required: true, message: this.$t('marketManagement.enterRotationTime') }],
        sourceIds: [{ required: true, message: this.$t('marketManagement.selectMaterial') }],
        type: [{ required: true }],
        direction: [{ required: true }]
      }
    }
  },
  async created() {
    if (!this.isCreated) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.currentItem[key]
      })

      this.form.sourceIds = this.currentItem.sourceIds
      if (this.currentItem?.sourceId) {
        this.materialList = []
        let sourceId = this.currentItem.sourceId.split(',')
        try {
          const res = await this.$api.marketManagement.materialList({
            currentPage: 1,
            pageSize: 5000
          })
          const list = (res.result && res.result.list) || []
          sourceId.forEach(id => {
            let item = list.find(_ => _.id === +id)
            if (item) {
              this.materialList.push(item)
            }
          })
        } catch (e) {
          console.log(e)
        }
      }

      this.repeatTime = this.currentItem.repeatTime.split('').map(Number)

      if (this.currentItem.isLongTermDelivery) {
        this.inputType = 1
        this.inputDate = [new Date(this.currentItem.startDate), new Date(this.currentItem.endDate)]
      }

      if (this.currentItem.isAllDayBroadcast) {
        this.playType = 1
        if (this.currentItem.startTime == 'Invalid date') {
          this.playTime = null
        } else {
          const ymd = this.$utils.formatTime(Date.now(), 'YYYY-MM-DD')
          this.playTime = [
            new Date(`${ymd} ${this.currentItem.startTime}`),
            new Date(`${ymd} ${this.currentItem.endTime}`)
          ]
        }
      }

      if (this.currentItem.publishObjectIds) {
        let arr = this.currentItem.publishObjectIds.split(',')
        if (this.currentItem.publishRange === 2) {
          //按sn投放
          this.selectData = arr.map(v => {
            return { sn: v }
          })
        } else if (this.currentItem.publishRange === 1) {
          //按组投放
          const groupRes = await this.$api.deviceManagement.groupList({ name: '', pageSize: -1 })
          const groupList = groupRes.result.list || []
          this.selectData = arr.map(id => {
            const item = groupList.find(b => b.id == id)
            if (item) return item
            return { id }
          })
        } else if (this.currentItem.publishRange === 3) {
          //按设备投放
          const modelRes = await this.$api.deviceManagement.modelList({ name: '', pageSize: -1 })
          const modelList = modelRes.result.list || []
          this.selectData = arr.map(id => {
            const item = modelList.find(b => b.id == id)
            if (item) return item
            return { id }
          })
        } else if (this.currentItem.publishRange === 6) {
          //全量
          this.deviceTotal = this.currentItem.publishObjectIds
        }
      }
    }
  },
  computed: {
    total() {
      if (this.form.publishRange === 2) {
        //sn
        return this.selectData.length
      } else {
        return this.selectData.reduce((a, b) => {
          return a + (b.deviceTotal || 0)
        }, 0)
      }
    }
  },
  watch: {
    inputType(val) {
      if (!val) {
        this.inputDate = null
      }
      this.form.isLongTermDelivery = val ? 1 : 0
    },
    playType(val) {
      if (!val) {
        this.playTime = null
      }
      this.form.isAllDayBroadcast = val ? 1 : 0
    }
  },
  methods: {
    typeChange() {
      this.materialList = []
    },
    publishWayChange(val) {
      this.selectData = []
      this.currentWay = this.publishWayList.find(_ => _.value === val).label
      if (val === 6 && !this.deviceTotal) {
        this.deviceTotalHandler()
      }
    },
    async deviceTotalHandler() {
      try {
        const res = await this.$api.deviceManagement.deviceTotal()
        this.deviceTotal = res.result
      } catch (err) {}
    },
    renderData(data) {
      this.materialList = data
    },
    pre() {
      if (this.active-- < 0) this.active = 0
    },
    async next() {
      if (this.active === 2) {
        try {
          await this.submit()
        } catch (e) {
          console.log(e)
        }
      } else {
        if (this.active === 1) {
          if (this.form.isLongTermDelivery && !this.inputDate) {
            return this.$message.warning(this.$t('groupManagement.selectLaunchTime'))
          }

          if (this.form.isAllDayBroadcast && !this.playTime) {
            return this.$message.warning(this.$t('groupManagement.selectPlayTime'))
          }
        }

        this.active++
      }
    },
    async submit() {
      if (!this.selectData.length && this.form.publishRange !== 6 && this.isCreated) {
        return this.$message.warning(this.$t('groupManagement.selectLaunchContent'))
      }
      if (this.deviceTotal >= 3000) {
        return this.$message.warning(this.$t('groupManagement.fullReleaseTips'))
      }

      const sendData = { ...this.form }

      if (sendData.publishRange === 6) {
        sendData.publishObjectIds = this.deviceTotal
      } else {
        sendData.publishObjectIds = this.selectData.map(item => item.sn || item.id).join(',')
      }

      if (this.repeatTime.length) {
        sendData.repeatTime = this.repeatTime.join('')
      }

      if (this.inputDate) {
        sendData.startDate = this.$utils.formatTime(this.inputDate[0], 'YYYY-MM-DD')
        sendData.endDate = this.$utils.formatTime(this.inputDate[1], 'YYYY-MM-DD')
      }

      if (this.playTime) {
        sendData.startTime = this.$utils.formatTime(this.playTime[0], 'HH:mm')
        sendData.endTime = this.$utils.formatTime(this.playTime[1], 'HH:mm')
      }

      if (!this.isCreated) {
        sendData.id = this.currentItem.id
      }

      if (sendData.type === 2) {
        delete sendData.carouseTime
      }

      sendData.sourceIds = (this.materialList || []).map(item => ({
        sort: item.sort,
        sourceId: item.id
      }))

      try {
        this.loading = true

        const res = await this.$api.marketManagement.checkSourceLaunchInfo(sendData)

        if (res.result) {
          sendData.launchIds = res.result
          await this.$confirm(res.summary, this.$t('tips'), {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel')
          })
        }

        try {
          this.isCreated
            ? await this.$api.marketManagement.programAdd(sendData)
            : await this.$api.marketManagement.programUpdate(sendData)
          this.$message.success(
            this.isCreated ? this.$t('groupManagement.launchSuccess') : this.$t('editSuccess')
          )
          this.$emit('getData')

          this.show = false
        } catch (e) {
          console.log(e)
        }
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    delHandler(index) {
      this.materialList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.addProgramModal {
  .mainWrap {
    display: flex;
    justify-content: space-around;
    padding-bottom: 20px;
    .leftPart {
      width: 50%;
      min-width: 350px;
      height: 245px;

      div {
        width: 100%;
        height: 100%;
      }

      img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      video {
        width: 100%;
        height: 100%;
      }
    }

    .rightPart {
      padding: 0;

      .modeWrap {
        margin-left: 52px;
        margin-top: 15px;
        p {
          font-size: 13px;
          margin-top: 8px;
        }
      }

      .select {
        width: 100%;
        margin-bottom: 10px;
        height: 50px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px dashed #d9d9d9;
        &:hover {
          border-color: #ef2b23;
        }
        .el-icon-plus {
          font-size: 40px;
          color: #ccc;
        }
        span {
          font-size: 12px;
        }
      }

      .materialList {
        display: flex;
        flex-direction: column;
        max-height: 120px;
        overflow-y: auto;
        p {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          line-height: 20px;

          .el-icon-close {
            position: static;
            font-size: 16px;
          }
        }
      }
    }
  }

  .group {
    .g-header {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      align-items: center;
      .r {
        display: flex;
        .info {
          margin-right: 10px;
        }
      }
    }
    .content {
      overflow: auto;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      &.hide {
        display: none;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 10px;
      max-height: 380px;
      .item {
        display: inline-block;
        overflow: hidden;
        padding: 8px;
        justify-content: space-between;
        border: 1px solid #ccc;
        line-height: 20px;
        align-items: center;
        width: 130px;
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;
        .item-left {
          display: flex;
          float: left;
          flex-direction: column;
          .name {
            word-break: break-word;
            font-size: 14px;
          }
          .num {
            font-size: 12px;
            color: #666;
          }
        }
        .item-right {
          font-size: 20px;
          float: right;
          margin-top: 10px;
          cursor: pointer;
          width: 0;
          height: 0;
          border-top: 20px solid #f56c6c;
          border-left: 20px solid transparent;
          position: absolute;
          right: 0;
          margin: 0;
          top: 0;
          &::after {
            content: 'x';
            position: absolute;
            left: -10px;
            font-size: 12px;
            color: #fff;
            top: -25px;
          }
        }
      }

      .sn {
        width: 250px;
        height: 20px;
      }

      .device-type {
        .item-left {
          flex-direction: row;

          img {
            width: 46px;
            max-height: 120px;
            cursor: zoom-in;
          }
        }
        .svg-icon {
          width: 25px;
          height: 25px;
        }

        .item-left > div {
          margin-left: 10px;
          span {
            display: block;
          }
        }
      }

      .noData {
        color: #d8d8d8;
        height: 200px;
        width: 100%;
        text-align: center;
        line-height: 200px;
      }
    }
  }

  .footer {
    padding: 20px 0 10px;
    display: flex;
    justify-content: center;
  }
}
</style>
